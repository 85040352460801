<template>
    <div class="account-details">
        <div class="account-details__details">
        <p class="account-details__details--title">Detalii organizatie</p>
        <div class="account-details__details-info">
            <img v-auth-image="$axios.defaults.baseURL+data.image" class="account-details__details-info--image account-details__details-info--image-personal"/>
            <div class="account-details__details-info--text account-details__details-info--text-personal">
            <p>{{this.data.name}}</p>
            </div>
        </div>
        </div>
        <div class="account-details__actions">
            <v-btn 
                @click.stop="openEditModal = true" 
                class="chartarium-button-hollow chartarium-button-hollow--primary" 
                rounded 
                elevation="0" 
                color="#fff"
            >
                <span>Editare Detalii</span>
                <inline-svg src='/img/pencil.svg' width="18" height="18"/>
            </v-btn>
        </div>

        <account-custom-edit-modal
            v-model="openEditModal" 
            :btnIcon="'save'" 
            :btnIconSize="24" 
            :title="'Editare organizatie'" 
            :btnTitle="'Salveaza'" 
            :selectedItem="data"
            :isAccount="true"
            @refreshAccount="$emit('refreshAccount')"
        />
    </div>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
import AccountCustomEditModal from '../molecules/AccountCustomEditModal.vue'
export default {
    components: {
        InlineSvg,
        AccountCustomEditModal
    },
    props:['data'],
    data(){
        return {
            openEditModal:false
        }
    }
}
</script>