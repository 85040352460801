<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="620"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">{{title}}</h1>
                
                
                <div class="app-modal__card--folder-title">
                    <div class="app-modal__account-image-wrapper" @click='$refs.fileInput.click()'>
                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event)'>
                        <img
                            v-if="image.preview || selectedItem.image === null"
                            :class="isAccount?'app-modal__account-image':'app-modal__account-image--customize'"
                            height="75"
                            contain
                            :src='image.preview'
                        />
                        <img 
                            :class="isAccount?'app-modal__account-image':'app-modal__account-image--customize'"
                            v-if="image.preview === null && selectedItem.image !== null" 
                            v-auth-image="$axios.defaults.baseURL+selectedItem.image" 
                            height="75" 
                        />
                        <span class="app-modal__account-image-icon">
                            <inline-svg src='/img/pencil.svg' width="12" height="12"/>
                        </span>
                    </div>
                    <p class="app-modal__roles-title">Nume</p>
                    <v-text-field 
                        v-model="temp.name" 
                        outlined 
                        placeholder="Nume"
                        :rules="[() => !!temp.name || 'Campul este obligatoriu']"
                        ref="title"
                    ></v-text-field>
                </div>
                
            </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="postAccount">
                    <inline-svg :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#2e4dd4"/>
                    <span>{{btnTitle}}</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value','selectedItem','isAccount'],
    components:{
        'inline-svg':InlineSvg
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            temp:{
                name:null,
                image:null
            },
            image:{
                preview:null
            },
            selected:null,
            loading: false,
        }
    },
    mounted(){
        this.selected = JSON.parse(JSON.stringify(this.selectedItem));
        this.temp.name = this.selected.name;
        this.temp.image = null;
    },
    methods: {
        close(){
            this.show = false;
            this.selected = JSON.parse(JSON.stringify(this.selectedItem));
            this.temp.name = this.selected.name;    
            this.temp.image = null;
            this.image.preview = null;  
            this.$refs.form.resetValidation();
        },
        validate(){
            let valid = true;
            if(this.temp.name === null || this.temp.name === undefined ) {
                valid = false;
            }
            return valid;
        },
        postAccount(){
            this.loading = true;
            if(!this.validate()) {
               this.$toasted.error('Campul `Nume` este obligatoriu');
               this.loading = false;
               return; 
            }

            let formData = new FormData();  

            if(this.temp.image === null || this.temp.image === undefined){
                delete this.temp.image;
            }
           
            for(const key in this.temp){
                if(Object.prototype.hasOwnProperty.call(this.temp,key)){
                    let value = this.temp[key];
                    formData.append(key, value);
                }
            }
            
            this.$axios.post('/accounts/'+this.$route.params.account_id, formData)
            .then(()=>{
                this.show = false;
                this.$toasted.success('Detaliile organizatiei au fost actualizate');
                this.$emit('refreshAccount');
                this.close();
            })
            .catch(err=>{
                this.$toasted.error(err)
                this.show = false;
            })
            
        },

        onFileChange(e){
            var files = e.target.files || e.dataTransfer.files;
            if(!files.length){
                return;
            }
            this.temp.image = files[0];
            this.createImage(files[0]);
        },

        createImage(file){

            var reader = new FileReader();
            var self = this;

            reader.readAsDataURL(file);
            reader.onload = function(e) {
                let image = new Image();
                image.src= e.target.result;
                image.onload = function () {
                    // self.mediaDim['HeightRight'] = this.height;
                    // self.mediaDim['WidthRight'] = this.width;    
                }
                self.image.preview = e.target.result;
                self.$forceUpdate();
            };
            this.$refs.fileInput.value = '';
            
        },

        removePreview(){
            this.image.preview = null;
            this.$forceUpdate();
        },
    }

}
</script>