<template>
    <v-item-group 
        mandatory 
        class="chartarium-toggle"
        v-model="selectedPlan"
        @change="changeValue"
    >
        <div v-for="plan in plans" :key="plan.id">
            <v-item 
                v-slot="{ active, toggle }"
                :value="plan.id"
                ripple
            >
                <div 
                    :class="active ? 'chartarium-toggle__button--active' : ''"
                    class="chartarium-toggle__button"
                    @click="toggle"
                    ripple
                >
                    {{plan.name}}
                </div>
            </v-item>
        </div>
    </v-item-group>
</template>
<script>
export default {
    props:['data'],
    data(){
        return{
            selectedPlan:1,
            plans:[
                {
                    id:1,
                    name:'Individual'
                },
                {
                    id:2,
                    name:'Organizatie'
                }
            ]
        }
    },
    mounted(){
        this.$emit('activeToggle',this.selectedPlan);
    },
    methods: {
        changeValue(){
            this.$emit('activeToggle',this.selectedPlan);
        }
    }
}
</script>