<template>
    <div v-if="this.roles">
        <div class="account-details">
            <div class="account-details__details">
                <p class="account-details__details--title">Roluri</p>
            </div>
            <div class="account-details__actions">
                <v-btn 
                    @click.stop="openAddModal = true" 
                    class="chartarium-button-hollow chartarium-button-hollow--primary" 
                    rounded 
                    elevation="0" 
                    color="#fff"
                >
                    <span>Adauga</span>
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </div>
        </div>
        <crud-table :data='roles' :fields='userFields' :canEdit="true" :canDelete="true"  @edit="editModal" @delete="deleteModal"/>
        <role-modal
            v-model="openAddModal" 
            :btnIcon="'save'" 
            :btnIconSize="24" 
            :title="'Adauga Rol'" 
            :btnTitle="'Salveaza'" 
            :permissions="this.permissions"
            @fetchUsers="getRoles"
        />
        <role-edit-modal
            v-model="openEditModal" 
            :btnIcon="'save'" 
            :btnIconSize="24" 
            :title="'Editare Rol'" 
            :btnTitle="'Salveaza'" 
            :selectedItem="selectedItem"
            :permissions="this.permissions"
            @fetchUsers="getRoles"
        />
        <delete-role-modal
            v-model="openDeleteModal" 
            :btnIcon="'modal-close'"
            :btnIconSize="24" 
            :title="'Sterge Rol'" 
            :btnTitle="'Sterge'" 
            :selectedItem="selectedItem"
            @deleteUser="getRoles"
        />
    </div>
</template>
<script>
import CrudTable from '../atoms/RolesCrudTable.vue';
import RoleModal from '../molecules/AddRoleModal.vue';
import RoleEditModal from '../molecules/EditRoleModal.vue';
import DeleteRoleModal from '../molecules/DeleteRoleModal.vue';
export default {
    components: {
        CrudTable,
        RoleModal,
        RoleEditModal,
        DeleteRoleModal
    },
    data(){
        return{
            roles:null,
            permissions:null,
            openAddModal:false,
            openEditModal:false,
            openDeleteModal:false,
            selectedItem:null,
            userFields: [
                {
                    label:'Nr. Crt.',
                    getValue: item =>item.id,
                    key:'id',
                    ref:'id'
                },
                {
                    label:'Nume Rol',
                    getValue: item =>item.name,
                    key:'name',
                    ref:'name'
                },
                {
                    label:'Permisiuni',
                    getValue: item =>item.permissions,
                    key:'permission',
                    ref:'permission',
                    type: 'list'
                }
            ]
        }
    },
    mounted(){
        this.getRoles();
        this.getPermissions();
    },
    methods: {
        getRoles(){
            this.$axios.get('/accounts/'+this.$route.params.account_id+'/roles')
                .then(res=>{
                    this.roles = res.data
                })
        },
        getPermissions(){
            this.$axios.get('/permissions')
                .then(res=>{
                    this.permissions = res.data
                })
        },
        editModal($event){
            this.openEditModal = true;
            this.selectedItem = JSON.parse(JSON.stringify($event));
        },
        deleteModal($event){
            this.openDeleteModal = true;
            this.selectedItem = $event;
        }
    }
}
</script>