<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="620"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form" autocomplete="off">
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">{{title}}</h1>
                
                
                <div class="app-modal__card--folder-title">
                    <p class="app-modal__roles-title">Parola</p>
                    <v-text-field 
                        v-model="temp.password" 
                        outlined 
                        placeholder="Parola"
                        ref="password"
                        name="password"
                        :rules="[() => !!temp.password || 'Campul este obligatoriu']"
                        :append-icon="display.password ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="display.password ? 'text' : 'password'"
                        @click:append="display.password = !display.password"
                        autocomplete="off"
                    ></v-text-field>
                    <p class="app-modal__roles-title">Parola Noua</p>
                    <v-text-field 
                        v-model="temp.password_new" 
                        outlined 
                        placeholder="Parola Noua"
                        ref="new_password"
                        name="new_password"
                        :rules="[() => !!temp.password_new || 'Campul este obligatoriu']"
                        :append-icon="display.password_new ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="display.password_new ? 'text' : 'password'"
                        @click:append="display.password_new = !display.password_new"
                        autocomplete="off"
                    ></v-text-field>
                    <p class="app-modal__roles-title">Confirma Parola Noua</p>
                    <v-text-field 
                        v-model="temp.password_confirmation" 
                        outlined 
                        placeholder="Confirma Parola Noua"
                        ref="confirm_password"
                        name="confirm_password"
                        :rules="[() => !!temp.password_confirmation || 'Campul este obligatoriu']"
                        :append-icon="display.password_confirmation ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="display.password_confirmation ? 'text' : 'password'"
                        @click:append="display.password_confirmation = !display.password_confirmation"
                        autocomplete="off"
                    ></v-text-field>
                </div>
                
            </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="putPass">
                   <v-icon v-if='mdi'>{{this.btnIcon}}</v-icon>
                    <inline-svg v-else :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#F21B3F"/>
                    <span>{{btnTitle}}</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value','mdi'],
    components:{
        'inline-svg':InlineSvg
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            temp:{
                password:null,
                password_new:null,
                password_confirmation:null
            },
            loading: false,
            display:{
                password:false,
                password_new:false,
                password_confirmation:false
            }
        }
    },
    methods: {
        close(){
            this.show = false;     
            this.temp={
                password:null,
                password_new:null,
                password_confirmation:null
            }
            this.$refs.form.resetValidation();
        },
        validate(){
            let valid = true;

            for(const key in this.temp){
                if(this.temp[key]===null){
                    valid = false;
                }
            }
            return valid;
        },
        putPass(){
            this.loading = true;
            if(!this.validate()) {
               this.$toasted.error('Toate campurile sunt obligatorii');
               this.loading = false;
               return; 
            }
            
            this.$axios.post('https://chartarium.weareomni.eu/api/app/auth/change-password', this.temp)
            .then(()=>{
                this.show = false;
                this.$toasted.success('Parola a fost actualizata');
                this.close();
            })
            .catch(err=>{
                this.$toasted.error(err)
                this.show = false;
            })
            
        },
    }

}
</script>