<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="620"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">{{title}}</h1>
                
                
                <div class="app-modal__card--folder-title">
                    <p class="app-modal__roles-title">Nume User</p>
                    <v-text-field 
                        v-model="temp.name" 
                        outlined 
                        placeholder="Nume User"
                        :rules="[() => !!temp.name || 'Campul este obligatoriu']"
                        ref="name"
                    ></v-text-field>

                    <p class="app-modal__roles-title">Permisiuni</p>
                    <v-item-group 
                        multiple
                        mandatory
                        class="app-modal__permissions"
                        v-model="temp.permissions"
                    >
                        <div v-for="(per) in permissions" :key="per.id">
                            <v-item 
                                v-slot="{active, toggle }"
                                active-class='app-modal__role--active'
                                :value="per.id"
                            >
                                <div 
                                    :class="active ? 'app-modal__role--active' : ''"
                                    class="app-modal__role"
                                    @click="toggle"
                                >
                                    {{per.name}}
                                </div>
                            </v-item>
                        </div>
                    </v-item-group>
                </div>
                
            </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="postUser">
                    <inline-svg :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#2e4dd4"/>
                    <span>{{btnTitle}}</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value','permissions','selectedItem'],
    components:{
        'inline-svg':InlineSvg
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        perIds(){
            return this.selectedItem.permissions?.map(el=>{
                return el.id;
            })
        }
    },
    watch: {
        selectedItem(newItem){
            if(newItem!==null||newItem!==undefined){
                this.temp.name = newItem.name;
                this.temp.permissions = this.perIds;
            }
        }
    },
    data() {
        return {
            temp:{
                name:null,
                permissions:[]
            },
            selected:null,
            loading: false,
        }
    },

    methods: {
        close(){
            this.show = false;      
            this.temp={
                name:null,
                permissions:[]
            },
            this.$refs.form.resetValidation();
        },

        validate(){
            let valid = true;
            if(this.temp.name === null || this.temp.permissions.length === 0) {
                valid = false;
            }

            return valid;
        },

        postUser(){
            this.loading = true;
            if(!this.validate()) {
               this.$toasted.error('Toate campurile sunt obligatorii');
               this.loading = false;
               return; 
            }

            let temp = this.permissions.filter(el => {
                if(this.temp.permissions.includes(el.id)){
                    return true;
                } else {
                    return false;
                }
            });

            this.temp.permissions = temp;

            
            this.$axios.put('/accounts/'+this.$route.params.account_id+'/roles/'+this.selectedItem.id, this.temp)
            .then(()=>{
                this.show = false;
                this.$toasted.success('Rolul a fost updatat');
                this.$emit('fetchUsers');
                this.close();
            })
            .catch(err=>{
                this.$toasted.error(err)
                this.show = false;
            })
            
        },
    }

}
</script>