<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="620"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">{{title}}</h1>
                <br>
                <p class="app-modal__text" v-if="!isUser">Esti sigur ca doresti sa stergi contul?<br>Nu vei mai putea accesa resursele salvate pana acum.</p>
                <p class="app-modal__text" v-if="isUser">Esti sigur ca doresti sa elimini acest user?<br>Aceasta actiune va fi definitiva.</p>
                
            </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn app-modal__btn--delete" elevation="4" rounded color="#fff" @click.stop="deleteFolder">
                    <v-icon v-if='mdi'>{{this.btnIcon}}</v-icon>
                    <inline-svg v-else :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#F21B3F"/>
                    <span>{{btnTitle}}</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import { mapActions } from 'vuex';
import InlineSvg from 'vue-inline-svg';
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value','mdi','selectedItem',"isUser"],
    components:{
        'inline-svg':InlineSvg
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {}
    },
    mounted(){
    },
    methods: {
         ...mapActions(['logoutAccount']),
        close(){
           this.show = false;
        },
        deleteFolder(){
            if(this.isUser){
                this.$axios.delete('/accounts/'+this.$route.params.account_id+"/users/"+this.selectedItem.id)
                    .then(()=>{
                        this.show = false;
                        this.$toasted.success('Userul a fost exclus');
                        this.$emit('deleteUser');
                    })
                    .catch(err=>{
                        this.$toasted.error(err)
                        this.show = false;
                    });  
            } else {
                this.$axios.delete('/accounts/'+this.$route.params.account_id)
                    .then(()=>{
                        this.show = false;
                        this.$toasted.success('Constul a fost Sters');
                        this.logoutAccount()
                            .then(()=>{
                                this.$router.push('/account');
                            })
                    })
                    .catch(err=>{
                        this.$toasted.error(err)
                        this.show = false;
                    }); 
            }        
        },
        
    }

}
</script>